/* !Este contenedor almacenara todo lo que sera el admin */
.conter-admin {
  background-color: #f5f5f5;
  width: 100%;
}
.hijos-conted {
  width: 100%;
  padding: 5px;
}
.box-cont-admin {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 20px;
}
.box-admin {
  width: 100%;
  background-color: #fff;
  border-radius: 9px;
  padding: 5px;
  margin-top: 20px;
}
/* ?APPBAR el menu del administrador sera muy diferente, este estara de flotando a la izquierda */
.conter-appBar {
  background-color: #fff;
}
.with-navbar {
  width: 10px;
}
.logo-admin {
  width: 120px;
}
.logo-admin a img {
  width: 100%;
}
.footer-nav-admins {
  padding: 5px;
}
.footer-nav-admins a {
  padding: 5px;
  color: #b0bcce;
  display: flex;
  align-items: center;
  text-decoration: none;
}
.footer-nav-admins a:hover {
  padding: 5px;
  color: #878e99;
}
.footer-nav-admins a svg {
  font-size: 30px;
}
/* //****************** */
.footer-nav-admin {
  border-top: 1px solid #e1e5eb;
  padding: 5px;
  margin-top: 5px;
}
.footer-nav-admin a {
  padding: 5px;
  color: #b0bcce;
  display: flex;
  align-items: center;
  text-decoration: none;
}
.footer-nav-admin a:hover {
  padding: 5px;
  color: #878e99;
}
.footer-nav-admin a svg {
  font-size: 30px;
}
.text-appBar {
  display: flex;
  align-items: center;
  justify-content: left;
  color: #fff;
  text-decoration: none;
  padding: 5px;
}
.text-appBar label {
  color: black;
  transition: 0.3s;
  cursor: pointer;
}
.text-appBar:hover {
  cursor: pointer;
  background-color: #f5f5f5;
  color: #232952;
}
.ico-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  margin-right: 10px;
}
.ico-menu img {
  width: 100%;
  height: 100%;
}
/* ? TITULO DE LOS MODULOS */
.title-module {
  text-align: center;
  margin-top: 20px;
  font-size: 30px;
}
/* ! BOTON DE NUEVO REGISTRO */
.botom-table {
  display: flex;
  align-items: center;
  justify-content: right;
}
.botom-table button {
  margin-right: 20px;
}
/* ? ICONOS DE LAS TABLAS */
.conter-icons {
  display: block;
}
.conter-icons button {
  margin: 2px;
}
.img-table {
  height: 100%;
  width: 100%;
  border-radius: 5px;
  padding: 5px;
  background-size: cover;
}
/* *************************************************** 
         TODO: El dashboar ira en este apartado
* ***************************************************/
.title-admin {
  text-align: left;
  font-weight: 700;
  margin-top: 20px;
}
.chart {
  margin: 20px;
}
.conter-toast {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
}
