@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  font-family: "Raleway", Helvetica, Arial, Lucida, sans-serif;
}

h1 {
  font-weight: 700;
}
.required {
  color: red;
  margin-top: 10px;
  margin-right: 10px;
  font-size: 20px;
}
.form-control1 {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.conter-img-modasl {
  width: 100%;
  height: 100%;
}
.conter-img-modasl img {
  width: 100%;
  height: 100%;
}
/* TABLA */
.conter-table {
  width: 90%;
  margin: 0 auto;
  margin-bottom: 40%;
}
.boton-tabla {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* /*****CSS PREDEFINIDO*** */

.top-20 {
  margin-top: 20px;
}

.top-50 {
  margin-top: 50px;
}

.bottom-50 {
  margin-bottom: 50;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}

.margin-90 {
  width: 90%;
  margin: 0 auto;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* BOTONES */

.btn1 {
  text-align: center;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border-radius: 0.6em;
  color: #fff;
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  font-size: 11px;
  line-height: 1;
  margin: 20px;
  padding: 9px;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  border: 2px solid #ffffff34;
}

.btn1:hover {
  background-color: transparent;
  border: 2px solid #ffffff34;
}

.btn-primary {
  background-color: rgb(9, 86, 131);
  text-align: center;
}

.btn-primary:hover {
  border: 2px solid rgb(9, 86, 131);
  color: rgb(9, 86, 131);
}

.btn-secondary {
  background-color: rgb(40, 84, 216);
}

.btn-secondary:hover {
  border: 2px solid rgb(40, 84, 216);
  color: rgb(40, 84, 216);
}

/*  COLORES */
.color-primary {
  color: #2ec3cd;
}

.color-secondary {
  color: #2d477c;
}

.color-gray {
  color: rgba(128, 128, 128, 0.767);
}
.color-naranja {
  color: #2d477c;
}
/* HEADER DE LA APP */
/* *****************NAVBAR******************* */
.navbarShop {
  background: var(
    --r-globalnav-topbar-override,
    var(
      --r-topbar-background-color,
      var(--r-globalmessage-segment-background-color, #fbfbfd)
    )
  );
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.37);
}

.conter-navbar {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 5px;
}

.position-nav {
  padding: 5px;
  height: 100%;
  transform: translateX(-100%);
  left: 0;
}

.toogel-nav {
  display: flex;
  align-items: center;
  margin-left: 20px;
}
.conter-nav-li label {
  position: relative;
  cursor: pointer;
}
.conter-nav-li label::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, #3a3a3a, #1d1c1c, #000000);
  z-index: 1;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.5s ease-in-out;
}
.conter-nav-li label:hover::before {
  transform: scaleX(1);
}
/* LOGO EN EL NAV  */
.footer-img {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-img a {
  text-decoration: none;
  width: 40%;
  height: 100%;
  color: rgb(244, 86, 7);
  font-weight: 700;
  text-align: center;
}

.footer-img a div {
  width: 100%;
  height: 100%;
}
.footer-img a img {
  width: 100%;
  height: 100%;
}

.footer-img a > p {
  text-align: center;
  font-size: 2em;
  letter-spacing: 8px;
  margin: 0;
}

.footer-img a > label {
  letter-spacing: 4px;
  color: rgb(80, 80, 80);
}

/* boton del menu */
.togel {
  margin-left: 20px;
  border: 0;
  border-radius: px;
  background-color: transparent;
  color: #20272b;
  font-size: 30px;
}

.conter-ico-nav {
  margin-right: 20px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: right;
}

.conter-ico-nav i {
  color: #20272b;
  font-size: 20px;
  margin: 20px;
  cursor: pointer;
}

.conter-ico-nav i:hover {
  color: #656769;
}

.conter-nav-li {
  display: flex;
  align-items: center;
  padding: 5px;
}

.conter-nav-li i {
  margin: 20px;
  font-size: 1.5em;
}

/* ! DRAWE */
.header-drawes button {
  float: right;
  padding: 5px;
  margin: 20px;
  font-size: 50px;
}
.conter-drawes {
  height: 10vh;
  display: flex;
  align-content: center;
  justify-content: center;
}
.conter-drawes a {
  text-decoration: none;
  color: black;
}
.conter-drawes div {
  height: 100%;
  margin-left: 20px;
  margin-right: 20px;
  text-align: center;
  font-size: 20px;
}
.conter-drawes div img {
  width: 100%;
  height: 100%;
}
/* *****************PAGINAS APP*************** */
/* HOME */
.conter-home {
  width: 100%;
  margin-top: 50px;
}

.text-home {
  display: block;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: black;
}

.text-home a {
  text-decoration: none;
  margin-left: 20px;
  color: black;
  text-align: center;
}

.text-home a > p {
  font-size: 5em;
  letter-spacing: 50px;
  margin: 0;
}

.text-home a > label {
  letter-spacing: 12px;
  color: rgb(80, 80, 80);
}

.resul-home {
  align-items: center;
  margin: 12vh;
  background-color: #2c57f10e;
  border-radius: 5px;
  padding: 5px;
}
.text-resul {
  margin: 20px;
}
.text-resul h1 {
  font-weight: bold;
  font-size: 4em;
}
.text-resul p {
  font-size: 2em;
}
.img-resul {
  height: 50vh;
  padding: 5px;
}
.img-resul div {
  float: right;
  width: 60%;
  height: 100%;
}
.img-resul div img {
  width: 100%;
  height: 100%;
}
.conter-search {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10vh;
  margin-bottom: 10px;
  width: 100%;
}

.conter-log-red {
  position: relative;
  bottom: 0;
  width: 100%;
  background-color: #20272b;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.conter-log-red div {
  margin: 20px;
  height: 100%;
  width: 50px;
}

.conter-log-red div img {
  height: 100%;
  width: 100%;
}

.box-vendido {
  color: black;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 30px;
}
/* !LISTA DE TODOS LOS PRODUCTOS */
.text-precio {
  color: #2d477c;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}
.text-precio label {
  margin-left: 5px;
}
.conter-pro {
  margin-bottom: 180px;
}
.card-pro-list {
  width: 260px;
}
.card-pro-list a {
  padding: 5px;
}
.card-pro-list a div {
  width: 95%;
  margin: 0 auto;
}
.img-cards {
  height: 50vh;
  background-size: cover;
}
.title-card-list {
  font-size: 19px;
  margin: 20px;
  font-weight: 700;
}
.descripcio {
  font-family: Arial, Helvetica, sans-serif;
  text-align: justify;
  margin: 5px;
}

.precio {
  text-align: center;
  text-decoration: none;
  font-size: 26px;
  display: flex;
}

.precio p {
  margin-right: 10px;
  text-decoration: line-through;
}

.precio a {
  text-decoration: none;
}

.btn-card {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 5px;
}

.btn-card button {
  margin: 5px;
  padding: 6px;
  font-family: 700;
  border-radius: 5px;
  background-color: rgb(40, 84, 216);
  color: #fff;
  border: 2px solid rgb(40, 84, 216);
}

.btn-card button:hover {
  background-color: #fff;
  border: 2px solid rgb(40, 84, 216);
  color: rgb(40, 84, 216);
}
.boton-product {
  display: flex;
}
.boton-product button {
  margin: 2px;
}

/* FOTER MENU  */
.conter-footer-menu {
  bottom: 0;
  width: 100%;
  z-index: 20;
  position: fixed;
}

.box-menu {
  height: 100%;
  padding: 5px;
  margin: 0 auto;
  width: 70%;
  background-color: #fffffff1;
  border-radius: 20px;
  box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.26);
}

.box-menu a {
  text-decoration: none;
}

.conte-text-img {
  cursor: pointer;
  display: block;
  text-align: center;
  font-size: 20px;
  width: 100%;
  height: 100%;
}

.conte-text-img:hover {
  background-color: rgba(128, 128, 128, 0.178);
}

/*! CARRITO DE COMPRAS */
/*? CARRITO DE COMPRAS */
.conter-menu-pro {
  position: absolute;
  top: 50px;
  right: 0;
  background-color: #fff;
  z-index: 1;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  max-height: 600px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.cart-product {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.info-cart-product {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  flex: 0.8;
}

.cantidad-producto-carrito {
  font-weight: 400;
  font-size: 20px;
}

.titulo-producto-carrito {
  font-size: 15px;
  margin: 5px;
}

.icon-close {
  width: 25px;
  height: 25px;
}

.icon-close:hover {
  stroke: red;
  cursor: pointer;
}

.cart-total {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  gap: 20px;
}

.cart-total h3 {
  font-size: 20px;
  font-weight: 700;
}

.total-pagar {
  font-size: 20px;
  font-weight: 900;
}

.btn-clear-all {
  border: none;
  color: #fff;
  padding: 15px 0;
  display: block;
  width: 100%;
  border-radius: 10px;
  font-size: 20px;
  font-weight: 700;
  transition: all 0.3s ease;
  margin-top: 10px;
}

.btn-clear-all:hover {
  background-color: #fff;
}
.img-menu {
  width: 60px;
  height: 60px;
}

.img-menu img {
  width: 100%;
  height: 100%;
}

.cart-empty {
  padding: 5px;
  text-align: center;
  margin: 20px;
}

.conterProduct {
  color: #03a691;
  padding: 5px;
  font-size: 16px;
  position: absolute;
  top: 15px;
}

/* TODO: ICONO DEL BOTON DEL CARRITO DE COMPRAS */
.btn-cartd-product {
  padding: 5px;
  width: 40px;
  background-color: #fa4d08;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.1s;
}
.btn-cartd-product:hover {
  background-color: #fff;
  border: 2px solid #fa4d08;
}
.icon-addproduct {
  width: 100%;
  height: 100%;
}
.icon-addproduct img {
  width: 100%;
  height: 100%;
}

/* PASARELA DE PAGOS */
.logo-pagos {
  width: 100px;
  height: 50px;
}
.logo-pagos img {
  width: 100%;
  height: 100%;
}
.header-pay {
  margin-top: 10vh;
}

.conter-pay {
  text-align: center;
  margin-top: 50px;
  font-family: sans-serif;
}

.conter-pay p {
  font-size: 20px;
}

.conter-pay p span {
  margin: 10px;
}

.logos-pay {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
  margin: 0 auto;
  background-color: #20272b0a;
  border-radius: 6px;
}

.logos-pay div {
  margin: 20px;
}

.logos-pay div img {
  width: 100%;
  height: 100%;
}

/* file */
.box-file {
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 150px;
  background-color: #20272b0a;
  width: 60%;
  padding: 30px 60px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0px 0px 5px 0px #20272b5b;
}
.box-file button svg {
  font-size: 3em;
}
.drag-file-area {
  border: 2px dashed #03a691;
  border-radius: 40px;
  padding: 30px;
  width: 100%;
  text-align: center;
}

.custom-file-label {
  background-color: #f44f1a;
  color: white;
  padding: 5px;
  border-radius: 8px;
  cursor: pointer;
}

.custom-file-label::before {
  content: "Selecciona un archivo";
}

.custom-file-input:focus + .custom-file-label {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}

/**********************************************
***************LOGIN**************************
**********************************************  */
.conted-login {
  z-index: 100;
  width: 100%;
  height: 100%;
  background-color: #eaefff;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.conter-box-login {
  background-color: #fff;
  width: 40%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  padding: 7px;
}

.box-login {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  display: flex;
  align-items: center;
}

.form-login {
  color: rgb(40, 84, 216);
  background-color: #fff;
  text-align: center;
}

.inpunt-login {
  margin-top: 20px;
}

.conte-terminos {
  display: flex;
  align-items: center;
  justify-content: center;
}

.conte-terminos div {
  margin: 20px;
}

.conte-terminos div input {
  margin-right: 20px;
}

/* ***************************************
*****************PROFILE********************
****************************************** */
.conted-profile {
  margin-bottom: 30px;
}
.avatar-profile img {
  border-radius: 10%;
  width: 190px;
  height: 190px;
  border: 1px solid #c6c6c7;
  background-color: #e6eaee5e;
}

/* FOOTER DE LA WEB */
.conter-footer {
  background-color: #0b1416;
  z-index: 30;
  position: relative;
  bottom: 0;
}
.footer-logos-pay {
  display: block;
}
.footer-logos-pay div {
  margin: 20px;
  padding: 5px;
  width: 90%;
  height: 90%;
}
.footer-logos-pay div img {
  width: 100%;
  height: 100%;
}

.info-footer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  text-align: center;
  padding: 5px;
  color: #ffffff63;
}
.info-footer h1 {
  letter-spacing: 10px;
  color: #b8b4b4;
  font-size: 1.3em;
  margin: 20px;
}
.pro-footer {
  display: block;
}
.pro-footer li {
  text-align: left;
  margin: 20px;
}
.pro-footer a {
  color: #ffffff63;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  padding: 5px;
}

.pro-footer a::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, #7dbacd, #78e887, #eb8585);
  z-index: 1;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.5s ease-in-out;
}
.pro-footer a:hover::before {
  transform: scaleX(1);
}
.conter-cont-footer {
  display: block;
}
.conter-cont-footer div {
  display: flex;
  text-align: center;
  padding: 5px;
}
.conter-cont-footer div i,
p {
  margin-left: 10px;
}

/*! PRODUCTO POR CATEGORIA */
.conter-filter h1 {
  font-size: 30px;
  margin-bottom: 20px;
}
.conter-filter {
  height: 60vh;
  margin: 20px;
}
/* DETALLE DEL PRODUCTO */
.conter-detallepro {
  margin-top: 20px;
  margin-bottom: 5px;
}
.title-detalle {
  font-size: 2em;
  font-weight: 500;
  margin: 20px;
  margin-top: 50px;
}
.text-detalle {
  font-size: 20px;
  margin: 20px;
}
.img-detalle {
  width: 90%;
  height: 60vh;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
}
.ico-detalle {
  background-color: #ffffff6e;
  border-radius: 100%;
  padding: 5px;
  position: absolute;
  top: 0;
  left: 0;
  margin: 10px;
}
.ico-detalle:hover {
  background-color: #4747472c;
}
.ico-detalle svg {
  color: black;
}
.ico-detalle svg:hover {
  color: #ffffffc0;
}
.descrip-detalle {
  margin: 20px;
}
.boton-detalle {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
}
/* ? SLIDER DEL HOME */
.styles-module_carousel-arrow__26sRw {
  background: url(https://cdn.dsmcdn.com/web/production/slick-arrow.svg) 50%
    no-repeat;
  border: none;
  cursor: pointer;
  height: 80px;
  outline: 0;
  position: relative;
  top: 80px;
}
/*! **************************
**********MY DALY****
************************** */

.title-daly {
  font-size: 3em;
  margin: 20px;
  text-align: center;
}
.conter-daly {
  width: 90%;
  margin: 0 auto;
}
.alerta {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: none;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 0.975rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;

  border: 1px solid #0d6efd;
}
.conter-alerta {
  width: 100%;
  display: flex;
  padding: 5px;
  margin: 5px;
}
.text-alerta {
  width: 100%;
  font-size: 20px;
  display: flex;
  align-items: center;
}
.text-alerta div {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}
.text-alerta div label {
  margin-right: 5px;
}
.text-alerta strong {
  margin: 5px;
}
.ico-alerta {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
}
.ico-daly {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: right;
}
.ico-daly svg {
  font-size: 2.5em;
}
.title-ver-daly {
  font-size: 1.5em;
  font-weight: bold;
}
.img-pago {
  margin: 0 auto;
  width: 400px;
}
.img-pago img {
  height: 100%;
  width: 100%;
  border-radius: 9px;
  box-shadow: 0px 0px 5px 0px;
}
.boton-close {
  display: flex;
  align-items: center;
  justify-content: right;
}
.carusel2 {
  width: 100%;
}
.conter-vacio {
  display: none;
}
.carusel2 div {
  width: 100%;
}
.carusel2 div div {
  margin: 0px;
  width: 400px;
}
.carusel2 div div div {
  margin: 0px;
  width: 100%;
}
.absolute button {
  display: none;
}

.conter-alerts {
  width: 40%;
}
/*! FACTURA */
.conter-factura {
  border: 1px solid black;
  border-radius: 5px;
}
.head-factura {
  text-align: center;
  padding: 5px;
}
.img-factura {
  height: 100px;
  width: 200px;
  margin: 0 auto;
}
.img-factura img {
  height: 100%;
  width: 100%;
}
.resul-factura {
  background-color: whitesmoke;
  padding: 9px;
}
.produc-factura {
  display: flex;
}
.produc-factura div,
.subTotal-factura div {
  width: 50%;
}
.precio-pro-fac p {
  float: right;
}
.subTotal-factura {
  display: flex;
  padding: 5px;
  background-color: whitesmoke;
}
.precio-pro-fac h5 {
  float: right;
}
.fecha-factura {
  display: flex;
  align-items: center;
  justify-content: center;
}
.codigo-factura {
  display: flex;
}
.codigo-factura div {
  width: 50%;
}

.precio-pro-fac div {
  float: right;
  margin: 0;
}
.state-factura {
  background-color: #f13c00;
  width: 100%;
  padding: 5px;
  text-align: center;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
}
/* todo: en este apartado voy a implementar las categorias en el home */
.conter-cate-home {
  background-color: #2c57f10e;
}
.conter-cate-home h1 {
  text-align: center;
}
.box-cate-home {
  border-radius: 5px;
  display: block;
  align-items: center;
  justify-content: center;
}
.img-cate-home {
  border: none;
  border-radius: 9px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  margin: 0 auto;
  background-color: #ffffff;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.171);
}
.img-cate-home svg {
  font-size: 2em;
  color: #1d4ed8;
  fill: #1d4ed8;
}
.img-cate-home:hover {
  background-color: #1d4ed8;
  cursor: pointer;
}
.img-cate-home svg:hover {
  color: #fff;
  fill: #fff;
}
.img-cate-home img {
  width: 100%;
  height: 100%;
  background-size: cover;
  border-radius: 5px 5px 0px 0px;
  border: none;
}
.text-cate-home {
  border: 0px;
  margin: 20px;
}
.text-cate-home h1 {
  font-size: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
}
select option::before {
  font-family: "Font Awesome 5 Free"; /* Asegúrate de que coincide con la fuente utilizada por Font Awesome. */
  content: attr(data-icon);
  margin-right: 10px; /* Espacio entre el icono y el texto. */
}

.input-quantity {
  width: 50px;
  margin: 2px 5px;
  padding: 5px;
  border-radius: 5px;
}
input[type="number"] {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box; /* Asegura que el padding no afecte el ancho total */
}

.check {
  text-align: center;
  margin: 5px;
  padding: 5px;
  color: #28a745;
  text-decoration: none;
}
.check svg {
  margin-right: 5px;
}
.check a {
  color: #28a745;
}

/* todo SHOP */
.table-shop {
  display: flex;
  align-items: center;
  border-top: 1px solid rgba(128, 128, 128, 0.199);
  border-bottom: 1px solid rgba(128, 128, 128, 0.199);
}
.nombre-shop {
  display: flex;
  align-items: center;
  padding: 20px;
  width: 100%;
}
.text-nombre-shop {
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.text-nombre-shop button {
  border-radius: 0;
  color: #fff;
  font-size: 14px;
  border: none;
  font-weight: 700;
  padding: 5px;
  background-color: #2d477cc2;
  margin: 5px;
}
.text-nombre-shop button:hover {
  background-color: #2d477c;
}
.img-shop {
  width: 180px;
  height: 90px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.397);
}
.img-shop img {
  width: 180px;
  height: 90px;
}
.info-shop {
  display: flex;
  align-items: center;
  justify-content: right;
  font-size: 15px;
  font-weight: 600;
  font-family: monospace;
  margin-bottom: 5px;
}
.info-shop label {
  margin-bottom: 20px;
  text-align: justify;
}
/* TODO SALE */
.form-sale input {
  margin-top: 10px;
}
.form-sale div {
  margin-top: 10px;
}
.img-checkout-pago {
  height: 30px;
  padding: 0;
  margin: 0;
  display: flex;
  margin-left: 10px;
}
.img-checkout-pago div {
  padding: 0;
  margin: 0;
  width: 70px;
  margin-left: 5px;
}
.img-checkout-pago div img {
  width: 100%;
  height: 100%;
}
.list-sale {
  display: block;
}
.Conter-sale {
  margin: 5px;
}
.Conter-sale div {
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Conter-sale div span {
  background-color: #2d477c8f;
  color: #fff;
  padding: 5px;
  height: 39px;
  width: 40px;
  text-align: center;
  border-radius: 100%;
  margin: 5px;
}

.list-sale div img {
  height: 100%;
  border: 1px solid rgba(128, 128, 128, 0.658);
  border-radius: 5px;
}
.ico-navbar {
  text-decoration: none;
  color: #2d477c;
  margin-left: 10px;
  margin-right: 10px;
}
.ico-navbar svg {
  font-size: 25px;
}
.ico-navbar:hover {
  color: #2d477c88;
}
.next-login {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}
.next-login a {
  padding: 5px;
}
.next-login a span {
  margin: 5px;
}
.conter-table-pedidos {
  width: 90%;
  margin: 0 auto;
}
.table-pedidos {
  margin-bottom: 20px;
  margin-top: 20px;
  border: 2px solid rgba(128, 128, 128, 0.24);
}
.head-table-pedidos {
  padding: 5px;
  background-color: rgb(243, 243, 243);
}
.head-table-pedidos div {
  display: block;
  margin: 5px;
}
.parra-head-tab-pedi {
  font-weight: 700;
  font-size: 17px;
}
.status-table-pedidos div {
  width: 50%;
  float: right;
}
.status-table-pedidos div div {
  width: 100%;
}
.body-table-pedidos {
  padding: 5px;
  margin: 5px;
}
.img-table-pedidos {
  display: flex;
  align-items: center;
}
.body-table-pedidos div img {
  width: 120px;
  height: 120px;
  border-radius: 5px;
}
.body-table-pedidos div {
  padding: 5px;
}
.title-body-tab-pedi {
  display: flex;
  align-items: center;
  color: rgba(128, 128, 128, 0.664);
}
.title-body-tab-pedi svg {
  font-size: 40px;
}
.title-body-tab-pedi label {
  font-size: 22px;
}
.info-body-tab-pedi {
  margin-top: 10px;
  padding: 5px;
}
.info-body-tab-pedi label {
  display: block;
}

/* ************************** */
.conter-deta-pedi {
  width: 90%;
  margin: 0 auto;
  padding: 5px;
  margin-top: 20px;
}
.title-head-deta-pedi {
  display: flex;
}
.title-head-deta-pedi h5 {
  margin-right: 20px;
}
.head-deta-pedi div div {
  margin-top: 5px;
  background-color: rgb(46, 31, 31);
  width: 150px;
}

.body-deta-pedi,
.footer-deta-pedi,
.list-pro-deta-pedi {
  margin-top: 20px;
}
.targe-deta-pedi,
.list-pro-deta-pedi {
  border: 1px solid rgba(128, 128, 128, 0.377);
  padding: 10px;
}
.footer-deta-pedi {
  padding: 10px;
  border: 1px solid rgba(128, 128, 128, 0.377);
  margin-bottom: 100px;
}
.avances-deta-pedi label {
  background-color: rgba(128, 128, 128, 0.342);
  padding: 5px;
  border-radius: 100%;
  margin-right: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.avances-deta-pedi label svg {
  font-size: 25px;
}
.avances-deta-pedi div div {
  display: flex;
}
.indicador {
  display: flex;
  align-items: center;
  justify-content: center;
}
.indicador div {
  margin-left: 4px;
  border: 0px;
  height: 1px;
  width: 90%;
  background-color: rgba(128, 128, 128, 0.342);
}

.target-list-deta-pedi div {
  display: flex;
  align-items: center;
}
.target-list-deta-pedi div img {
  width: 90px;
  height: 90px;
  border-radius: 5px;
}
/* EN ESTA PAGINA TAMBIEN SE MANEJARA LAS @MEDIA CON UN MAXIMO DE WIDTH DE 1024px*/
@media (max-width: 1024px) {
  .footer-deta-pedi {
    margin-bottom: 40px;
  }
  .indicador div {
    display: none;
    width: 0px;
  }
  .footer-deta-pedi h6 {
    text-align: center;
  }
  .avances-deta-pedi div div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .text-home a > p {
  }
  .text-home a > p {
    font-size: 1em;
    letter-spacing: 20px;
    margin: 0;
  }
  .box-menu {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px;
  }
  .input-search {
    width: 100%;
    height: 100%;
  }
  .conter-img-home {
    display: none;
  }
  .precio {
    font-size: 40px;
    display: block;
    font-weight: 700;
  }
  .conter-drawes div {
    height: 10vh;
    width: 50%;
  }
  .conter-drawes div img {
    width: 40%;
  }
  .card-pro-list {
    width: 90%;
    margin: 0 auto;
  }
  .logo-asupro {
    width: 100%;
  }
  .conter-navbar {
    display: block;
  }

  .input-search {
    font-size: 5em;
  }
  .searchPro {
    font-size: 2em;
  }
  .conter-alerta {
    display: block;
    padding: 0px;
    margin: 0px;
  }
  .text-alerta {
    display: block;
  }

  .ico-alerta {
    display: block;
  }
  .img-pago {
    width: 80%;
  }
  .boton-close .btn svg {
    font-size: 2em;
  }
  .conter-box-login {
    height: 80%;
    width: 90%;
  }
  .resul-home {
    margin: 0px;
    margin-top: 12vh;
  }
  .img-resul div {
    width: 100%;
    height: 100%;
  }
  .styles-module_item-container__a8zaY {
    display: none;
  }
  .styles-module_carousel-arrow__26sRw {
    display: none;
  }
  .carusel2 {
    display: block;
    width: 100%;
  }
  .carusel2 div {
    width: 100%;
  }
  .carusel2 div div {
    margin: 0px;
    width: 100%;
  }
  .logos-pay {
    width: 100%;
  }
  .box-file {
    width: 100%;
  }
  .box-file button svg {
    font-size: 2em;
  }
  .img-detalle {
    height: 50vh;
  }
  .table-shop {
    display: block;
  }
  .text-resul {
    margin: 15px;
  }
  .text-resul h1 {
    font-weight: bold;
    font-size: 2em;
  }
  .text-resul p {
    font-size: 1.5em;
  }
  .img-resul {
    display: none;
  }
  .conter-search,
  .conter-cate-home,
  .box-vendido {
    margin-top: 0px;
  }
  .footer-img a {
    display: block;
    width: 200px;
    height: 40px;
  }
  .head-home-asupro {
    display: none;
  }
  .img-shop {
    width: 120px;
    height: 90px;
  }
  .img-shop img {
    width: 120px;
    height: 90px;
  }
  .conter-ico-nav {
    justify-content: left;
  }
  .toogel-nav {
    margin: 0px;
  }
  .togel {
    margin: 0px;
    margin-top: 3px;
  }
}

@media (max-width: 667px) {
  .card-pro-list {
    width: 100%;
  }
  .footer {
    display: none;
  }
}

/**/
.img-bg {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* ajusta el nivel de opacidad */
  z-index: 1;
}
